import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Login from './screens/Login';

const Home = React.lazy(() => import('./screens/Home'));
const Profile = React.lazy(() => import('./screens/Profile'));
const Categories = React.lazy(() => import('./screens/Categories'));
const Offer = React.lazy(() => import('./screens/Offer'));
const AllOffers = React.lazy(() => import('./screens/AllOffers'));
const EditOffer = React.lazy(() => import('./screens/EditOffer'));
const Partners = React.lazy(() => import('./screens/Partners'));
const UserTransaction = React.lazy(() => import('./screens/UserTransaction'));
const SinglePartner = React.lazy(() => import('./screens/SinglePartner'));
const SingleOffer = React.lazy(() => import('./screens/SingleOffer'));
const AddBlogs = React.lazy(() => import('./screens/AddBlogs'));
const AllBlogs = React.lazy(() => import('./screens/AllBlogs'));
const EditBlog = React.lazy(() => import('./screens/EditBlog'));
const Admins = React.lazy(() => import('./screens/Admins'));
const StaticOffers = React.lazy(() => import('./screens/StaticOffers'));
const PartnerOffer = React.lazy(() => import('./screens/PartnerOffer'));
const LeaderBoard = React.lazy(() => import('./screens/LeaderBoard'));
const MetaConfig = React.lazy(() => import('./screens/MetaConfig'));
const GrossData = React.lazy(() => import('./screens/GrossData'));
const AddPartner = React.lazy(() => import('./screens/AddPartner'));
const EditPartner = React.lazy(() => import('./screens/EditPartner'));
const PayPal = React.lazy(() => import('./screens/PayPal'));
const ReferalLeaderBoard = React.lazy(() => import('./screens/ReferalLeaderBoard'));
const WinnerLeaderBoard = React.lazy(() => import('./screens/WinnerLeaderBoard'));
const EarningLeaderBoard = React.lazy(() => import('./screens/EarningLeaderBoard'));
const AllUsers = React.lazy(() => import('./screens/AllUsers'));
const PendingEarning = React.lazy(() => import('./screens/PendingEarning'));
const Transaction2 = React.lazy(() => import('./screens/Transaction2'));
const Challenge = React.lazy(() => import('./screens/Challenge'));
const AllChallenge = React.lazy(() => import('./screens/AllChallenge'));
const EditChallenge = React.lazy(() => import('./screens/EditChallenge'));
const UserLogs = React.lazy(() => import('./screens/UserLogs'));
const UserPartnerData = React.lazy(() => import('./screens/UserPartnerData'));
const ReferLinkStat = React.lazy(() => import('./screens/ReferLinkStat'));
const InvalidUrlPage = React.lazy(() => import('./screens/InvalidUrlPage'));
const ConfigurationInfo = React.lazy(() => import('./screens/ConfigurationInfo'));
const OneUserTask = React.lazy(() => import('./screens/OneUserTask'));
const MultiPageData = React.lazy(() => import('./screens/MultiPageData'));
const AllMultiPageData = React.lazy(() => import('./screens/AllMultiPageData'));
const EditMultiPageData = React.lazy(() => import('./screens/EditMultiPageData'));
const SingleUserAllTask = React.lazy(() => import('./screens/SingleUserAllTask'));
const TaskLogs = React.lazy(() => import('./screens/TaskLogs'));
const Loader = React.lazy(() => import('../src/helperComponent/Loader'));


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('')

  useEffect(() => {
    const jwtToken = localStorage.getItem('JWT');
    if (jwtToken) {
      setIsAuthenticated(true);
      const admindata = localStorage.getItem("admin");
      const adminArray = JSON.parse(admindata);
      setRole(adminArray?.role)
    }

  }, []);
  function ProtectedRoute({ children }) {
    const jwtToken = localStorage.getItem('JWT');

    if (!jwtToken) {
      return <Navigate to="/login" />;
    }
    return children;
  }


  return (
    <>
      <Router>
        <Suspense>
          <Routes>
            <Route exact path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/users" element={<ProtectedRoute><AllUsers /></ProtectedRoute>} />
            {role == 3 && <Route path="/admins" element={<ProtectedRoute><Admins /></ProtectedRoute>} />}
            {role == 3 && <Route path="/grossData" element={<ProtectedRoute><GrossData /></ProtectedRoute>} />}
            <Route path="/login" element={isAuthenticated ? <Home /> : <Login />} />
            <Route path="/profile" element={<ProtectedRoute><Profile /> </ProtectedRoute>} />
            {role !== 1 && <Route path="/partners/:id" element={<ProtectedRoute> <SinglePartner /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/partners" element={<ProtectedRoute> <Partners /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/editpartner/:id" element={<ProtectedRoute><EditPartner /></ProtectedRoute>} />}
            {role !== 1 && <Route path="/offer/:id" element={<ProtectedRoute><SingleOffer /></ProtectedRoute>} />}
            {role !== 1 && <Route path="/AddPartner" element={<ProtectedRoute><AddPartner /></ProtectedRoute>} />}
            {role !== 1 && <Route path="/categories" element={<ProtectedRoute><Categories /></ProtectedRoute>} />}
            <Route path="/Home" element={<ProtectedRoute> <Home /> </ProtectedRoute>} />
            {role !== 1 && <Route path="/allOffers" element={<ProtectedRoute> <AllOffers /></ProtectedRoute>} />}
            {role !== 1 && <Route path="/allBlogs" element={<ProtectedRoute><AllBlogs /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/addBlog" element={<ProtectedRoute> <AddBlogs /> </ProtectedRoute>} />}
            {role !== 1 && <Route exact path="/offer" element={<ProtectedRoute><Offer /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/editVoucher" element={<ProtectedRoute> <EditOffer /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/editBlog" element={<ProtectedRoute>  <EditBlog /> </ProtectedRoute>} />}
            <Route path="/transaction" element={<ProtectedRoute>  <Transaction2 /> </ProtectedRoute>} />
            <Route path="/pendingearning" element={<ProtectedRoute>  <PendingEarning /> </ProtectedRoute>} />
            <Route path="/users/transaction/:id" element={<ProtectedRoute>  <UserTransaction /> </ProtectedRoute>} />
            {role !== 1 && <Route path="/staticOffers" element={<ProtectedRoute> <StaticOffers /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/partnerOffer/:id" element={<ProtectedRoute>  <PartnerOffer /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/metaconfig" element={<ProtectedRoute> <MetaConfig /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/leaderboard" element={<ProtectedRoute>  <LeaderBoard /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/referalleaderboard" element={<ProtectedRoute>  <ReferalLeaderBoard /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/winnerleaderboard" element={<ProtectedRoute>  <WinnerLeaderBoard /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/earningleaderboard" element={<ProtectedRoute>  <EarningLeaderBoard /> </ProtectedRoute>} />}
            {role !== 1 && <Route path="/paypal" element={<ProtectedRoute> <PayPal /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/challenge' element={<ProtectedRoute>  <Challenge /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/allchallenge' element={<ProtectedRoute>  <AllChallenge /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/editchallenge/:id' element={<ProtectedRoute> <EditChallenge /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/userLogs/:id' element={<ProtectedRoute>  <UserLogs /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/user/partnerData/:id' element={<ProtectedRoute>  <UserPartnerData /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/referlinkstat' element={<ProtectedRoute>  <ReferLinkStat /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/configurationInfo' element={<ProtectedRoute>  <ConfigurationInfo /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/oneUserTask/:id' element={<ProtectedRoute> <OneUserTask /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/multiPageData' element={<ProtectedRoute>  <MultiPageData /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/allmultiPageData' element={<ProtectedRoute>  <AllMultiPageData /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/editmultiPageData/:id' element={<ProtectedRoute>  <EditMultiPageData /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/singleUserAllTask/:id' element={<ProtectedRoute>  <SingleUserAllTask /> </ProtectedRoute>} />}
            {role !== 1 && <Route path='/taskLogs/:id' element={<ProtectedRoute> <TaskLogs /> </ProtectedRoute>} />}
            <Route path="*" element={<InvalidUrlPage />} />
          </Routes>
        </Suspense >

      </Router>
    </>

  );
}

export default App;
